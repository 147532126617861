@font-face {
  font-family: 'arialBold';
  src: local('arialBold'), url("assets/ARIALBOLDMT.OTF") format('truetype');
}
@font-face {
  font-family: 'arialExtraBold';
  src: local('arialExtraBold'), url("assets/ARIALMTEXTRABOLD.TTF") format('truetype');
}
@font-face {
  font-family: 'arialMedium';
  src: local('arialMedium'), url("assets/ARIALMTMEDIUM.TTF") format('truetype');
}
@font-face {
  font-family: 'arialArrow';
  src: local('arialArrow'), url("assets/ARIALMTNARROW.TTF") format('truetype');
}
@font-face {
  font-family: 'arialLight';
  src: local('arialLight'), url("assets/Nimbus-Sans-D-OT-Ultra-Light_32757.ttf") format('truetype');
}
/*@font-face {*/
/*  font-family: 'Roboto';*/
/*  src: local('Roboto'), url("./Applications/ekoco/assetsEkoco/Roboto-Regular.ttf") format('truetype');*/
/*}*/
.arialBold{
  font-family: arialBold;
}
.arialExtraBold{
  font-family: arialExtraBold;
}
.arialMedium{
  font-family: arialMedium;
}
.arialArrow{
  font-family: arialArrow;
}
.arialLight{
  font-family: arialLight;
}

/*.robotoFont{*/
/*  font-family: Roboto;*/
/*}*/

.swal2-container{
  z-index: 999;
  width: 100% !important;
}

/*Modifications de la scrollbar*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.root{
  background-color: blue;
  width:200px !important;
  transition:all .5s cubic-bezier(.25,.8,.25,1);
}

.root2{
  background: green;
  width:50px !important;
  transition : all .5s cubic-bezier(.25,.8,.25,1);
}

.MuiTableCell-root ,.MuiTableCell-body{
  /*padding: 4px !important;*/
}

tr > td > div > div > .MuiOutlinedInput-input{
  padding: 5px !important;
  width: 100%;
}
#mui-component-select-isNode,  #mui-component-select-formatDate, #mui-component-select-dateInReg{
  padding-right: 30px !important;
}

.bar {
  fill: steelblue;
}

.bar:hover {
  fill: #23415A;
}

.tooltip {
  position: absolute;
  opacity:0.8;
  z-index:10000;
  text-align:left;
  border-radius:4px;
  -moz-border-radius:4px;
  -webkit-border-radius:4px;
  padding:8px;
  color:#fff;
  background-color:#000;
  font: 12px sans-serif;
  max-width: 300px;
  pointer-events: none;
}


.MuiTableContainer-root{
  overflow: hidden;
}
.TouchableIcons{
  cursor:pointer;
}

.champsTexte{
  width:450px;
  margin-bottom: 15px !important;
}

.MuiPaper-elevation1{
  box-shadow: 0 0 white !important;
}

div>div>div>div>div>div>div>div>div> .MuiButtonBase-root, .MuiAccordionSummary-root{
  padding: 0px 8px !important;
  background-color: white !important;
  margin-top: -1px !important;
}

#buttonNotif{
  padding: 5px !important;
  background-color: white !important;
  margin-top: 0px !important;
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}

#buttonSuppSuffrage:hover{
  transition:all .5s cubic-bezier(.25,.8,.25,1);
  background-color: #e31c10;
  color: white;
}

.TransfertToken-dndWaiting-10:hover{
  background-color: darkorange !important;
}
.TransfertToken-dndUploaded-11:hover{
  background-color: greenyellow !important;
}

th {
  font-size: 18px !important;
}
td {
  font-size: 16px !important;
  padding:8px !important;
}

.MuiTooltip-tooltip{
  font-size: 15px !important;
}

#divCGU p  {
  font-size: 13px;
}

.buttonDrawerAdmin {
  display:flex;
  width: 100%;
  text-transform:none;
  justify-content:flex-start;
  cursor:pointer;
  padding: 5px 50px 5px 16px;
  margin:0px;
  z-index: 999999;
}
.buttonDrawerAdmin:hover {
  background-color: #efefef;
}

.MuiAccordionDetails-root{
  padding: 8px 0px 4px !important;
}

.MuiAccordionSummary-root.Mui-expanded{
  min-height: 0px !important;
}

.MuiDialog-paperWidthSm{
  max-width: 5000px !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.03,1.03);
  }
  100% {
    transform: scale(1,1);
  }
}

#hoverMoving:hover {
  animation: zoominoutsinglefeatured 1s infinite ;
  cursor:pointer;
}

#alwaysHover {
  animation: zoominoutsinglefeatured 1s infinite ;
  cursor:pointer;
}

#zoomInAndOut{
  animation: zoominoutsinglefeatured 1s infinite;
  cursor:pointer;
}

.swal2-toast{
  margin-right: 45px;
  font-size: 19px !important;
  width: 500px !important;
  max-width: 500px;
}

.badge-container {
  visibility: hidden;
}

.hoverMenuKageSecur{
  text-align: center;
}

.hoverMenuKageSecur:hover {
  box-shadow: inset 0 0 10px -5px #000000;
  cursor: pointer;
}

.hoverMenuKageSecur > p {
  transform: translate(-5px);
}

.hoverMenuKageSecur:hover > p {
  transition:all .5s cubic-bezier(.25,.8,.25,1);
  transform: translate(0px);
}

.clickChangeModeRecupClef{
  font-weight:bold;
  padding:5px;
  margin-bottom:0;
  cursor:pointer;
  border-bottom: none !important;
}

.dropzoneClefOubliee:hover{
  background-color: #ebebeb;
}

.numberInscSuffrage{
  border-radius: 50px;
  border-color:#6600FFFF;
  border-style: solid;
  border-width:14px;
  width:0;
  height:0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numberInscSuffrageOutside{
  border-radius: 50px;
  border-color: #4700c0;
  border-style: solid;
  border-width:16px;
  width:0;
  height:0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkIconSuffrage{
  border-radius: 50px;
  border-color: #6600FFFF;
  border-style: solid;
  border-width:22px;
  color:#FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconCodeSource{
  border-radius: 50px;
  border-style: solid;
  border-width:18px;
  color:#FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepperSuffrage .Mui-active{
  color:#6600FFFF !important;
}

.stepperSuffrage .Mui-completed{
  color:#6600FFFF !important;
}

.divBorderDialogSuffrage{
  border-radius: 15px;
  border-color: #6600FFFF;
  border-style: solid;
  border-width:2px;
  text-align: center;
  margin:5px;
  font-size: 18px;
}

.legendeDivBorderDialogSuffrage{
  position:absolute;
  font-size:12px;
  margin:0;
  margin-left:10px;
  color:#6600FFFF;
}

.inGroupDrag tr {
  border: 1px solid rgba(224, 224, 224, 1);
}

.inGroupDrag td {
  line-height:1.43;
  padding:15px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.inGroupDrag th {
  line-height:1.43;
  padding:13px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.radioSuffrage.Mui-checked{
  color:#6600FFFF !important;
}

.MuiMenu-paper{
  max-height: 250px !important;
}

.swal-offset-top{
  top:50px !important;
}

.hoverBlueCase:hover{
  border:1.5px solid #52A8D9FF !important;
}

/*.editorClassName{*/
/*  width: 200px;*/
/*}*/
.wrapperClassName{
  width: 550px;
}

._letterIn{
  display: inline-block;
  animation: letterIn 0.5s linear 0s 1 normal forwards;
}

._letterOut{
  display: inline-block;
  transform: translateY(-20px);
  animation: letterOut 0.5s linear 0s 1 normal forwards;
}

@keyframes letterIn{
  0%{
    display: inline-block;
    -webkit-transform: rotateX(-90deg) translateY(-40px);
    transform: rotateX(-90deg) translateY(-40px);
    transition: -webkit-transform .32s cubic-bezier(.55,.055,.675,.19);
    transition: transform .32s cubic-bezier(.55,.055,.675,.19);
    transition: transform .32s cubic-bezier(.55,.055,.675,.19),-webkit-transform .32s cubic-bezier(.55,.055,.675,.19);
  }
  100%{
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
    transition: -webkit-transform .38s cubic-bezier(.175,.885,.32,1.275);
    transition: transform .38s cubic-bezier(.175,.885,.32,1.275);
    transition: transform .38s cubic-bezier(.175,.885,.32,1.275),-webkit-transform .38s cubic-bezier(.175,.885,.32,1.275);
  }
}

@keyframes letterOut{
  0%{
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
    transition: -webkit-transform .38s cubic-bezier(.175,.885,.32,1.275);
    transition: transform .38s cubic-bezier(.175,.885,.32,1.275);
    transition: transform .38s cubic-bezier(.175,.885,.32,1.275),-webkit-transform .38s cubic-bezier(.175,.885,.32,1.275);
  }
  100%{
    -webkit-transform: rotateX(-90deg) translateY(40px);
    transform: rotateX(-90deg) translateY(40px);

    transition: -webkit-transform .32s cubic-bezier(.55,.055,.675,.19);
    transition: transform .32s cubic-bezier(.55,.055,.675,.19);
    transition: transform .32s cubic-bezier(.55,.055,.675,.19),-webkit-transform .32s cubic-bezier(.55,.055,.675,.19);
    display: none;
  }
}


/*ANIMATED LETTERS */

.animated-title-wrapper {
  /*display: inline-block;*/
  vertical-align: top;
  margin: 0;
  text-align: left;
  position: relative;
  width: 110% !important;
}

.word {
  position: absolute;
  opacity: 0;
  width: 650px;
}

.letter {
  display: inline-block;
  position: relative;
  float: left;
  transform: translateZ(25px);
  transform-origin: 50% 50% 25px;
}

.letter.out {
  transform: rotateX(90deg);
  transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.letter.behind {
  transform: rotateX(-90deg);
}

.letter.in {
  transform: rotateX(0deg);
  transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.divTuto{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 10px;
  padding:5px;
  cursor:pointer;
}

.divExplication{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 10px;
  padding:5px;
}

.divTuto:hover{
  background-color: #ebebeb;
}

.draggableDiv{
  backgroundColor:#FFFFFF;
  width:350px;
  position:absolute;
  borderRadius:5px;
  padding:10px ;
  zIndex:9999;
  textAlign:center;
  right:80px;
  box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
}

.flexCenter{
  display:flex;
  justify-content:center;
  align-items:center
}

.MuiTableContainerOverflow{
  overflow-y: auto !important;
}

.formStripe {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
  0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

#emailStripe {
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

/* Buttons and links */
.buttonStripe {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.buttonStripe:hover {
  filter: contrast(115%);
}

.buttonStripe:disabled {
  opacity: 0.5;
  cursor: default;
}

.editorMail .editorClassName {
  height: 200px;
}

.editorMail .wrapperClassName {
  width:100%;
  overflow: visible;
}

.divPVM{
  flex-direction: column;
  background-color: white;
  width:25vw;
  max-width: 500px;
  padding:10px;
  border-radius: 5px;
  margin:15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: center;
  justify-content: center;
}

.sortP{
  color: #969191;
}

.sortP:hover{
  color:#000000;
}

#divPres{
  background: rgb(157,209,240);
  background: linear-gradient(49deg, rgb(222, 241, 255) 0%, rgba(114,170,204,1) 100%);
  flex-direction:column;
  width:100%;
  height:600px;
}

#divPrevCatalogue{
  flex-direction:column;
  background-color: #ffffff;
  width:100%;
  height:700px;
  z-index: 3;
}

#divPrevMaVideo{
  flex-direction:column;
  background: rgb(157,209,240);
  background: linear-gradient(49deg, rgb(222, 241, 255) 0%, rgba(114,170,204,1) 100%);
  width:100%;
  height:700px;
  overflow-x: hidden;
}

#divPrevRevente{
  flex-direction:column;
  background-color: #FFFFFF;
  width:100%;
  height:700px;
  overflow: hidden;
}

#divFooter{
  background-color: #ffffff;
  width:100%;
  height:200px;
  box-shadow:rgba(100, 100, 111, 0.2) 29px 29px 29px 29px;
  z-index: 6;
}

.divPrevisu{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  flex-direction: column;
  margin:15px;
}

.circleCarousel{
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border: solid 1px black;
  border-radius: 50%;
  display: inline-block;
  margin:5px;
}

.arrowCarousel{
  transform:scale(1.5);
  cursor: pointer;
}

.divVideoToUpload{
  border: 2px solid white;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.circleAddVideo{
  height: 80px;
  width: 80px;
  background-color: #ffffff;
  border: solid 1px black;
  border-radius: 50%;
  margin:5px;
  cursor: pointer;
}

.circleAddVideo:hover{
  background-color: #9DD1F0FF;
}

.textShadowBorder{
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}

.circleUploadDone{
  height: 70px;
  width: 70px;
  background-color: #43c53b;
  border-radius: 50%;
}

.divBandeauPrixNFT{
  position:absolute;
  background-color:#000000;
  opacity:0.5;
  width:100%;
  border-radius:0px 0px 5px 5px;
  top:-29px;
  height: 25px;
}

.imageRevente{
  border-radius:5px;
  z-index:4;
  position:absolute;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transform: perspective(1000px) rotateX(15deg) rotateY(35deg) rotateZ(356deg) scale3d(1,1,1);
}

#translateLeft {
  animation: translateLeft 4s infinite ;
}

@keyframes translateLeft {
  0% {
    transform: perspective(1000px) rotateX(15deg) rotateY(35deg) rotateZ(356deg) scale3d(1,1,1) translateX(0px) translateZ(0px);
  }
  50% {
    transform: perspective(1000px) rotateX(15deg) rotateY(35deg) rotateZ(356deg) scale3d(1,1,1) translateX(-25px) translateZ(-25px);
  }
  100% {
    transform: perspective(1000px) rotateX(15deg) rotateY(35deg) rotateZ(356deg) scale3d(1,1,1) translateX(0px) translateZ(0px);
  }
}

#translateRight {
  animation: translateRight 4s infinite ;
}

@keyframes translateRight {
  0% {
    transform: perspective(1000px) rotateX(15deg) rotateY(35deg) rotateZ(356deg) scale3d(1,1,1) translateX(0px) translateZ(0px);
  }
  50% {
    transform: perspective(1000px) rotateX(15deg) rotateY(35deg) rotateZ(356deg) scale3d(1,1,1) translateX(25px) translateZ(25px);
  }
  100% {
    transform: perspective(1000px) rotateX(15deg) rotateY(35deg) rotateZ(356deg) scale3d(1,1,1) translateX(0px) translateZ(0px);
  }
}

.textCGU:hover{
  cursor: pointer;
  color: #969191;
}

.cardAccount{
  flex-direction:column;
  cursor:pointer;
  width:90px;
  height:90px;
  border-radius: 10px;
}

.menuBR{
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.menuBR:hover{
  color: #969191;
}

.divOpenMenuBR{

}

.divMyVideo{
  width:17vw;
  min-width:300px;
  min-height:200px;
  background-color: #ffffff;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  transform: scale(1);
  transition: all .5s cubic-bezier(.25,.8,.25,1);
  cursor: pointer;
  flex-direction: column;
}

.divMyVideo:hover{
  transform: scale(1.1);
}

.circleAdd{
  height: 60px;
  width: 60px;
  background-color: #ffffff;
  border: solid 1px black;
  border-radius: 50%;
  margin:5px;
  cursor: pointer;
}

.stepperChoiceMinia{
  border: 1px solid #72aacc;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}

.imageMinia{
  width:98%;
  margin:5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  cursor: pointer;
  border-radius: 5px;
}

.divContainImageMinia:hover{
  transition: scale .3s cubic-bezier(.25,.8,.25,1);
  transform: scale(1.6);
  z-index: 99999;
}

.divContainImageMinia:hover .imageMinia{
  opacity: 1 !important;
}

#dialogUpload>div .MuiPaper-root{
  overflow: visible !important;
}

#dialogDetailVideo video {
  width: 100%;
  height: auto;
}

.divMenuUser{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color:#FFFFFF;
  border-radius:5px;
  margin-top: 13px;
  transition: transform 0.26s ease;
  transform-origin: top;
  position:absolute;
  width:100px;
  padding:10px;
  height:fit-content;
  text-align: center;
}

.fontDivMenuUser:hover{
  color:#b6b6b6
}

.imageProfilPreview{
  width: 300px;
  cursor: pointer;
  border-radius: 10px;
}

.imageProfilPreview:hover{
  filter: brightness(0.8);
}

.divButtonBuyResale{
  padding: 15px;
  text-align: center;
  transition: all .5s cubic-bezier(.25,.8,.25,1);
  background-size: 200% auto;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #a5dcff 0%, #73bdec 51%, #a5dcff 100%);
  cursor: pointer;
  text-shadow: 1px 2px 3px rgba(0,0,0,0.3);
  font-weight: bold;
  user-select: none;
  border: 0;
  text-transform: none;
  font-size: 16px;
}

.divButtonBuyResale:hover:enabled {
  background-position: right center; /* change the direction of the change here */
  transform: translateY(-2px) translateX(-1px);
}

.divButtonBuyResale:active:enabled {
  box-shadow: rgba(50, 50, 93, 0.25) 0 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0 18px 36px -18px inset;
  transform: translateY(0px) translateX(0px);
  /*filter: grayscale(80%);*/
}

.divButtonBuyResale:disabled {
  cursor: default;
  background-image: linear-gradient(to right, #bebebe 0%, #939393 51%, #bebebe 100%);
  color: #efefef;
  /*filter: grayscale(80%);*/
}

.divMenuCat{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color:#FFFFFF;
  border-radius:5px;
  margin-top:28px;
  transition: transform 0.26s ease;
  transform-origin: top;
  position:absolute;
  width:50vw;
  max-width: 750px;
  min-width: 200px;
  padding:10px;
  height:fit-content;
  z-index: 9999;
}

.divButtonBuyResaleCat{
  padding: 15px;
  text-align: center;
  transition: all .5s cubic-bezier(.25,.8,.25,1);
  background-size: 200% auto;
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #FFFFFF;
  font-weight: bold;
  user-select: none;
  border: 0;
  text-transform: none;
  font-size: 16px;
}

.divButtonBuyResaleCat:hover:enabled {
  background-position: right center; /* change the direction of the change here */
  transform: translateY(-2px) translateX(-1px);
}

.divButtonBuyResaleCat:active:enabled {
  box-shadow: rgba(50, 50, 93, 0.25) 0 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0 18px 36px -18px inset;
  transform: translateY(0px) translateX(0px);
  /*filter: grayscale(80%);*/
}

.borderButtonBRCat{
  width: fit-content;
  height: fit-content;
  background-image: linear-gradient(to right, #a5dcff 0%, #73bdec 51%, #a5dcff 100%);
  border-radius: 5px;
  transition: all .5s cubic-bezier(.25,.8,.25,1);
  margin: 5px;
}

.containerEntrepriseCard{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  background-color: #FFFFFF;
  padding:10px

}

#divInvestir{
  flex-direction:column;
  background: rgb(255,235,182);
  background: linear-gradient(49deg, rgba(255,235,182,1) 0%, rgba(250,181,1,1) 100%);
  width:100%;
  height:700px;
  overflow: hidden;
}

.anim1sto{
  animation: anim1 3s forwards ;
  transform:scale(2.1) translateY(25px)
}

@keyframes anim1 {
  0% {
    transform:scale(2.1) translateY(25px);
  }
  85% {
    transform:scale(2.1) translateY(-60px);
  }
  95% {
    transform:scale(1.9) translateY(-60px);
  }
  100% {
    transform:scale(2.1) translateY(-60px);
  }
}

.anim2sto{
  animation: anim2 3s forwards ;
  filter: brightness(1);
}

@keyframes anim2 {
  0% {
    filter: brightness(1);
  }
  15% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(0.4);
  }
  100% {
    filter: brightness(0.4);
  }
}

.anim3sto{
  animation: anim3 3s forwards ;
  opacity: 0;
  transform:translateY(-150px);
}

@keyframes anim3 {
  0% {
    opacity: 0;
    transform:translateY(-150px);
  }
  15% {
    opacity: 0;
    transform:translateY(-150px);
  }
  50% {
    opacity: 1;
    transform:translateY(-150px);
  }
  90%{
    opacity: 1;
    transform:translateY(-150px);
  }
  95% {
    opacity: 1;
    transform:scale(0.98) translateY(-150px);
  }
  100% {
    opacity: 1;
    transform:scale(1) translateY(-150px);
  }
}

.imageExplication{
  width:150px;
  margin:10px;
  cursor: pointer;
  transform: scale(1) rotate(0deg);
  transition: transform 3s cubic-bezier(.25,.8,.25,1);
  position: absolute;
  transform-origin: center;
}

.imageExplication:hover{
  width: 180px !important;
  height: auto !important;
}

.imageExplicationClicked{
  width: 180px !important;
  height: auto !important;
}

.divContainerExplicationImage{
  transition: all 3s cubic-bezier(.25,.8,.25,1);
  transform-origin: center;
  position:relative;
  width:420px;
  height:420px;
}

.divLDF{
  width: 98%;
  padding:2%;
  border-radius: 10px;
  height: 400px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  margin-top: 20px;
  transition: all .2s cubic-bezier(.25,.8,.25,1);
  cursor: pointer;
}

.divLDF:hover{
  transform: scale(1.01);
}

.separatorSTO{
  width: 40px;
  height: 5px;
  background-color:#000;
  transform: rotate(325deg);
}

.divUploadFileEchange{
  width:13vw;
  height: 13vw;
  min-width:200px;
  min-height:200px;
  background-color: #ffffff;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  transform: scale(1);
  transition: all .5s cubic-bezier(.25,.8,.25,1);
  cursor: pointer;
  flex-direction: column;
}

.divUploadFileEchange:hover{
  transform: scale(1.02);
}

.divUploadFileEchangeCompact{
  width:150px;
  height: 150px;
  background-color: #ffffff;
  margin: 25px 15px 25px 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  transform: scale(1);
  transition: all .5s cubic-bezier(.25,.8,.25,1);
  cursor: pointer;
  flex-direction: column;
}

.darkenHover{
  border-radius: 10px;
}
.darkenHover:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.divListeAction{
  width: 98%;
  padding:2%;
  border-radius: 10px;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  margin-top: 20px;
  transition: all .8s cubic-bezier(.25,.8,.25,1);
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.divListeActionnaire{
  width: 99%;
  padding:1%;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  margin-top: 0px;
  transition: all .8s cubic-bezier(.25,.8,.25,1);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.divChoixInscription{
  padding:20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  transition: all .8s cubic-bezier(.25,.8,.25,1);
  display: flex;
  border-radius: 5px;
  margin:10px;
  cursor: pointer;
  width: 200px;
}

.divChoixInscription:hover{
  transform: scale(1.1);
}

.titleFAQ{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  transition: all .5s cubic-bezier(.25,.8,.25,1);
  cursor:pointer;
}

.divReceipt{
  width: 450px;
  height: fit-content;
  transition: all .5s cubic-bezier(.25,.8,.25,1);
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  border-radius: 5px;
  margin-top: -60px;
  flex-direction: column;
  justify-content: space-between;
  padding:20px;
  overflow: hidden;
}

/* STYLE DES ICONES DYNAMIQUS DE SWAL2  -> finswal2 */
.sa {
  width: fit-content;
  height: fit-content;
  background-color: transparent;
}
.sa-success {
  border-radius: 50%;
  border: 4px solid #a5dc86;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
}
.sa-success:after, .sa-success:before {
  background: #fff;
  content: "";
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.sa-success:before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.sa-success:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 4.25s ease-in;
}
.sa-success-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(165, 220, 134, 0.25);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.sa-success-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.sa-success-tip, .sa-success-long {
  background-color: #a5dc86;
  border-radius: 2px;
  height: 5px;
  position: absolute;
  z-index: 2;
}
.sa-success-tip {
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  width: 25px;
  animation: animateSuccessTip 0.75s;
}
.sa-success-long {
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  width: 47px;
  animation: animateSuccessLong 0.75s;
}

@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg);
  }
  100%, 12% {
    transform: rotate(-405deg);
  }
}
.sa-error {
  border-radius: 50%;
  border: 4px solid #F27474;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
  animation: animateErrorIcon 0.5s;
}
.sa-error:after, .sa-error:before {
  background: #fff;
  content: "";
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.sa-error:before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.sa-error:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 4.25s ease-in;
}
.sa-error-x {
  display: block;
  position: relative;
  z-index: 2;
}
.sa-error-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(200, 0, 0, 0.2);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.sa-error-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.sa-error-left, .sa-error-right {
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
  background-color: #F27474;
  top: 37px;
  width: 47px;
}
.sa-error-left {
  left: 17px;
  transform: rotate(45deg);
  animation: animateXLeft 0.75s;
}
.sa-error-right {
  right: 16px;
  transform: rotate(-45deg);
  animation: animateXRight 0.75s;
}

@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg);
  }
  100%, 12% {
    transform: rotate(-405deg);
  }
}
@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes animateXLeft {
  0%, 65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}
@keyframes animateXRight {
  0%, 65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}

/* FIN DU STYLE DES ICONES DYNAMIQUS DE SWAL2 -finswal2-*/


/*Affiche 3 petits points avec une animation derriere un texte*/
.loadingText:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

.divButtonDropZone{
  padding:15px;
  border:1px solid #000000;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  cursor: pointer;
}

.divButtonDropZone:hover{
  background-color: #FFFFFF;
}

#menuRightClick{
  position: fixed;
  z-index: 1000;
  width: 210px;
  background-color: #f8f8f8;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform .3s cubic-bezier(.25,.8,.25,1);
  transform: scale(0);
  transform-origin: top left;
  visibility: hidden;
}

#menuRightClick .options{
  text-align: left;
  margin: 0;
  padding: 8px 0 8px 0;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  display: flex;
  border:0;
  background-color: transparent;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
}

#menuRightClick .options:after{
  content: '';
  position: absolute;
  border-bottom: 1px solid #707070;
  left: 0;
  width: 70%;
  margin:39px 15% 0 15%;
  opacity: 0.4;
}

#menuRightClick .options:last-child::after{
  border-bottom: 0 solid #707070;
}

#menuRightClick .options:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0 18px 36px -18px inset;
}

.selectableLineTDB{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding: 10px 10px 10px 30px;
  position:absolute;
  max-height: 34px;
  user-select: none;
  /*width: calc(100% - 44px);*/
}

.selectableLineTDB:hover{
  background-color: #c3e2ff !important;
}

#divPresBTOB{
  /*background: rgb(157,209,240);*/
  /*background: linear-gradient(49deg, rgb(222, 241, 255) 0%, rgb(54, 73, 130) 100%);*/
  background-color: #364982FF;
  flex-direction:column;
  width:100%;
  height:600px;
}

.divButtonBtoBDesign{
  padding: 15px;
  text-align: center;
  transition: all .5s cubic-bezier(.25,.8,.25,1);
  background-size: 200% auto;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #6074ad 0%, #364982 51%, #6074ad 100%);
  cursor: pointer;
  text-shadow: 1px 2px 3px rgba(0,0,0,0.3);
  font-weight: bold;
  user-select: none;
  border: 0;
  text-transform: none;
  font-size: 16px;
}

.divButtonBtoBDesign:hover:enabled {
  background-position: right center; /* change the direction of the change here */
  transform: translateY(-2px) translateX(-1px);
}

.divButtonBtoBDesign:active:enabled {
  box-shadow: rgba(50, 50, 93, 0.25) 0 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0 18px 36px -18px inset;
  transform: translateY(0px) translateX(0px);
  /*filter: grayscale(80%);*/
}

.divButtonBtoBDesign:disabled {
  cursor: default;
  background-image: linear-gradient(to right, #bebebe 0%, #939393 51%, #bebebe 100%);
  color: #efefef;
  /*filter: grayscale(80%);*/
}

.mainDivConcept{
  margin:10px 10px 10px 0;
  width: 335px;
  height: 380px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 3px;
}

.dropzoneBtoB{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  height: 150px;
  width: 100%;
  margin:0px;
  transition: all .8s cubic-bezier(.25,.8,.25,1);
  background: transparent;
  cursor: pointer;
  padding: 10px;
}

.dropzoneBtoB:hover{
  background: rgba(0,0,0,0.1);
}

.previBtoBIllusConcept:hover{
  cursor: pointer;
}

.previBtoBIllusConcept:hover .previBtoBIllusConceptOpacity{
  /*background: rgba(0,0,0,0.5);*/
  opacity: 0.5;
}

.displayDeletePreviBtoBIllusConcept{
  display: none;
  opacity: 1 !important;
}

.previBtoBIllusConcept:hover .displayDeletePreviBtoBIllusConcept{
  display: flex !important;
  opacity: 1 !important;
}

.divEtapeBTOB{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #364982;
  cursor: pointer;
}

.divEtapeBTOBCompleted{
  background-color: #364982;
}

.divBubbleBtoB {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #FFFFFF;
  cursor: pointer;
}

.MuiTooltip-popper{
  z-index: 15000 !important;
}

.full-screen-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.full-screen-message .corner {
  position: absolute;
  width: 75px;
  height: 75px;
  margin:5vw;
  border-radius: 15px;
  pointer-events: none;
}

.full-screen-message .corner.top-right {
  top: 0;
  right: 0;
  border-top: 20px solid #F18F29FF;
  border-right: 20px solid #F18F29FF;
}

.full-screen-message .corner.top-left {
  top: 0;
  left: 0;
  border-top: 20px solid #F18F29FF;
  border-left: 20px solid #F18F29FF;
}

.full-screen-message .corner.bottom-left {
  bottom: 0;
  right: 0;
  border-bottom: 20px solid #F18F29FF;
  border-right: 20px solid #F18F29FF;
}

.full-screen-message .corner.bottom-right {
  bottom: 0;
  left: 0;
  border-bottom: 20px solid #F18F29FF;
  border-left: 20px solid #F18F29FF;
}

.loaderTextKS {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}
.loaderTextKS::after {
  content: '';
  width: 5px;
  height: 5px;
  background: currentColor;
  position: absolute;
  bottom: 0;
  right: -15px;
  box-sizing: border-box;
  transform: translateY(-100%);
  animation: animloaderKS 1s linear infinite;
}

@keyframes animloaderKS {
  0% {
    box-shadow: 10px 0 rgba(255, 255, 255, 0), 20px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 10px 0 #000, 20px 0 rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 10px 0 #000, 20px 0 #000;
  }
}

/* The switch - the box around the slider */
.switchKS {
  position: relative;
  display: inline-block;
  width: 60px;
  min-width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switchKS input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.sliderKS {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderKS:before {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #ccc;
  content: "✖";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .sliderKS {
  background-color: #52A8D9;
}

input:focus + .sliderKS {
  box-shadow: 0 0 1px #52A8D9;
}

input:checked + .sliderKS:before {
  color:#4BB543;
  content:"✔";
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sliderKS.round {
  border-radius: 34px;
}

.sliderKS.round:before {
  border-radius: 50%;
}

.paypalButtonContainer{
  width:100%
}

.paypalButtonComponent{
  width:100%
}

.champsTexteEkoco::-webkit-input-placeholder { /* WebKit browsers */
  color:    #fff;
}
.champsTexteEkoco:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #fff;
}
.champsTexteEkoco::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #fff;
}
.champsTexteEkoco:-ms-input-placeholder { /* Internet Explorer 10+ */
  color:    #fff;
}

.champsTexteEkocoRose::-webkit-input-placeholder { /* WebKit browsers */
  color:    #EC5BA1FF;
}
.champsTexteEkocoRose:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #EC5BA1FF;
}
.champsTexteEkocoRose::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #EC5BA1FF;
}
.champsTexteEkocoRose:-ms-input-placeholder { /* Internet Explorer 10+ */
  color:    #EC5BA1FF;
}


.buttonSK {
  padding: 15px;
  text-align: center;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-size: 200% auto;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ffffff 0%, #ffffff 51%, #ffffff 100%);
  cursor: pointer;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  user-select: none;
  font-family: SKFont, sans-serif;
  border: 0;
  text-transform: none;
  font-size: 16px;
}

.buttonSK:hover:enabled {
  background-position: right center;
  transform: translateY(-2px) translateX(-1px);
  background-position: 100% center; /* Add this line to move the background image to the right */
}

.buttonSK:active:enabled {
  box-shadow: rgba(50, 50, 93, 0.25) 0 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0 18px 36px -18px inset;
  transform: translateY(0px) translateX(0px);
}

.buttonSK:disabled {
  cursor: default;
  background-image: linear-gradient(to right, #bebebe 0%, #939393 51%, #bebebe 100%);
  color: #efefef;
}